import React, { useContext } from "react"
import { InstantSearch, Configure } from "react-instantsearch-dom"

import { Locale } from "../../context"
import { searchClient } from "../../utils/algolia/searchClient"
import { CustomHits } from "../../components/search"

const Similar = ({ property, global }) => {
  let { locale } = useContext(Locale)

  return (
    <>
      <div className="container">
        <InstantSearch
          searchClient={searchClient}
          indexName={`${process.env.GATSBY_ALGOLIA_INDEX_NAME}_${locale}`}
          analytics={false}
        >
          <Configure filters={`city.name:${property.city.name} AND propertyStatus.name:${property.propertyStatus.name} AND propertyType.name:${property.propertyType.name} AND price > ${property.price * 0.9} AND NOT reference:${property.reference}`} hitsPerPage={3} />
          <CustomHits global={global} similar />
        </InstantSearch>
      </div>
    </>
  )
}

export default Similar
