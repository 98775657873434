import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import { Gallery, Plans } from "../../components/blocks"
import Layout from "../../components/Layout"
import Video from "../../components/Video"
import VirtualTour from "../../components/VirtualTour"

import Hero from "./Hero"
import Map from "./Map"
import Contact from "./Contact"
import Description from "./Description"
import Similar from "./Similar"

const Index = ({ data }) => {
  const imageTags = [
    {
      tagName: "meta",
      attributes: {
        property: "og:image",
        content: data.property.seoImage[0] && data.property.seoImage[0].url,
      },
    },
    {
      tagName: "meta",
      attributes: {
        name: "twitter:image",
        content: data.property.seoImage[0] && data.property.seoImage[0].url,
      },
    },
  ]

  const metaTags = {
    tags: data.property.seoMetaTags.tags
      .map(tag =>
        data.property.description &&
        tag.attributes &&
        ((tag.attributes.name && tag.attributes.name.includes("description")) ||
          (tag.attributes.property &&
            tag.attributes.property.includes("description")))
          ? {
              ...tag,
              attributes: {
                ...tag.attributes,
                content: data.property.description
                  .substring(0, 160)
                  .concat("…"),
              },
            }
          : tag
      )
      .concat(imageTags),
  }

  const unavailable = data.property.propertyStatus.originalId === "34805497" || data.property.propertyStatus.originalId === "34805527"

  return (
    <Layout transparentHeader>
      <HelmetDatoCms
        htmlAttributes={{
          lang: data.property.locale,
        }}
        seo={metaTags}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": ["Product", "SingleFamilyResidence"],
            name: data.property.title,
            description: data.property.description,
            url: `https://ikeys-realty.com/${data.property.locale}/${data.property.slug}`,
            image: [data.property.seoImage[0] && data.property.seoImage[0].url],
            numberOfRooms: data.property.rooms,
            accommodationCategory: ["6625335", "34805527", "6625337"].includes(
              data.property.propertyStatus.originalId
            )
              ? "RLSE"
              : "RESI",
            floorSize: {
              "@type": "QuantitativeValue",
              value: data.property.surface,
              unitCode: "MTK",
            },
            leaseLength:
              data.property.propertyStatus.originalId === "6625335"
                ? {
                    "@type": "QuantitativeValue",
                    value: 1,
                    unitText: "months",
                  }
                : false,
            numberOfBathroomsTotal: data.property.bathrooms,
            numberOfBedrooms: data.property.bedrooms,
            yearBuilt: data.property.constructionYear,
            sku: data.property.reference,
            mpn: data.property.reference,
            brand: {
              "@type": "Organization",
              name: "iKeys Realty",
              logo: {
                "@type": "ImageObject",
                url: "https://ikeys-realty.com/ikeys-logo.png",
              },
            },
            address: {
              "@type": "PostalAddress",
              addressCountry: data.property.city.country.code,
              addressLocality: data.property.city.name,
              postalCode: data.property.postalCode,
              streetAddress: data.property.address,
            },
            geo: {
              "@type": "GeoCoordinates",
              latitude: data.property.map.latitude,
              longitude: data.property.map.longitude,
            },
            telephone: data.property.agent.phone,
            offers: {
              "@type": "Offer",
              url: `https://ikeys-realty.com/${data.property.locale}/${data.property.slug}`,
              priceCurrency: data.property.currency.code,
              price: data.property.price,
              priceValidUntil: "2030-12-31",
              itemCondition: "https://schema.org/NewCondition",
              availability: ["6625332", "6625335", "6625337"].includes(
                data.property.propertyStatus.originalId
              )
                ? "https://schema.org/InStock"
                : "https://schema.org/SoldOut",
            },
            photo: data.property.gallery.map(image => ({
              "@type": "ImageObject",
              contentUrl: image.fluid.src,
            })),
            amenityFeature: data.property.amenities.map(amenity => ({
              "@type": "LocationFeatureSpecification",
              name: amenity.name,
              value: true,
            })),
          })}
        </script>
        {data.property._allSlugLocales.map((version, index) => (
          <link rel="alternate" hreflang={version.locale} href={`https://ikeys-realty.com/${version.locale}/${version.value}`} key={index} />
        ))}
      </HelmetDatoCms>
      <div className={`property ${unavailable && `mb-12 md:mb-24`}`}>
        <Hero property={data.property} />
        <Description property={data.property} search={data.search} />
        <Video video={data.property.video} global={data.global} />
        <VirtualTour
          virtualTour={data.property.virtualTour}
          global={data.global}
        />
        <Gallery data={data.property.gallery} global={data.global} />
        <Plans plans={data.property.floorPlans} global={data.global} />
        <Map property={data.property} />
        {!unavailable &&
          <>
            <Contact data={data} property={data.property} />
            <Similar property={data.property} global={data.global} />
          </>
        }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PropertyQuery(
    $locale: String!
    $slug: String!
  ) {
    property: datoCmsProperty(locale: { eq: $locale }, slug: { eq: $slug }) {
      ...Property
    }
    global: datoCmsGlobal(locale: { eq: $locale }) {
      ...Global
    }
    search: datoCmsSearch(locale: { eq: $locale }) {
      ...Search
    }
  }
`

export default Index
