import React from "react"
import Iframe from "react-iframe"

import { Heading } from "./utils"

const VirtualTour = ({ virtualTour, global }) => (
  <>
    {virtualTour && (
      <section className="container py-12 md:py-24">
        {global && (
          <Heading title={global.virtualTourTitle} className="mb-12" center />
        )}
        <div className="relative overflow-hidden transform translate-y-0 rounded-lg">
          <Iframe
            url={virtualTour}
            width="100%"
            height="100%"
            className="h-[90vh]"
            display="initial"
            position="relative"
          />
        </div>
      </section>
    )}
  </>
)

export default VirtualTour
